import { useState, useEffect } from "react";
import axios from "axios";

function Hospitals() {
    const [datasets4, setDataSets4] = useState([{ name: "" }]);

    useEffect(() => {
    (async () => {
        const r = await axios({
        method: "get",
        headers: {
          "db": "prod",
        },
        url: `https://golang.api.healthtag.io/hospital`,
        withCredentials: false,
        });
        if (r.status === 200) {
          for (let i = 0; i < r.data.length; i++) {
            if (r.data[i].lastUpdated !== "") {
              r.data[i].lastUpdated = new Date(new Date(r.data[i].lastUpdated).setHours(new Date(r.data[i].lastUpdated).getHours() + 7)).toUTCString().substring(0, 22);
              let threeDaysAgoUnixTime = new Date() - (3 * 24 * 60 * 60 * 1000);
              let lastUpdatedUnixTime = new Date(r.data[i].lastUpdated);
              if (lastUpdatedUnixTime < threeDaysAgoUnixTime) {
                r.data[i].warning = true;
              }
              r.data[i].lastUpdated += " น.";
            }
          }
          setDataSets4(r.data);
        }
    })();
    }, []);

  return (
    <nav className='flex flex-col w-full'>
        <div className='flex w-full h-[60px] bg-[#F5F5F5] text-[16px] items-center px-2 rounded-lg'>
            รายชื่อโรงพยาบาล
        </div>
        <ul className="w-full overflow-auto text-[14px] text-[#39434F]">
            {datasets4.map((item, index) => {
              return (
                <div className="flex items-center justify-between">
                  <li key={index} className='hover:bg-gray-100 p-2'>{item.name}</li>
                  <span className={`${item.warning ? "text-red-300 font-bold" : ""}`}>{item.lastUpdated}</span>
                </div>
              );
            })}
        </ul>
    </nav>
  )
}

export default Hospitals