import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Matic from "../assets/svg/matic.png";

function PoolPHR() {
  const [data, setData] = useState();
  const [balance, setBalance] = useState();
  const ADDRESS = "0x2f2963d1Ce65E01F06c5Cda78E34ad21cE8c8B99";

  function convertWeiToMatic(wei) {
    return wei / 1000000000000000000;
  }

  useEffect(() => {
    (async () => {
      try {
        let r = await axios({
          method: "get",
          headers: {
            "db": "prod",
          },
          url: `https://golang.api.healthtag.io/pool`,
          withCredentials: false,
        });
        if (r.status === 200) {
          setData(r.data.length);
        }

        r = await axios({
          method: "get",
          url: `https://api.polygonscan.com/api?module=account&action=balance&address=${ADDRESS}&apikey=${process.env.REACT_APP_API_KEY} `,
          withCredentials: false,
        });
        if (r.status === 200) {
          setBalance(convertWeiToMatic(r.data.result).toFixed(4));
        }
      } catch (error) {
        console.log(error + " PoolPHR");
      }
    })();
  }, []);

  return (
    <div className="w-full h-auto items-center grid grid-cols-1 xl:flex justify-between mt-4 flex-wrap">
      <div className="flex-[0.5] h-full xl:h-[100px] items-center grid grid-cols-1 xl:flex justify-center bg-white rounded-xl p-6">
        <a
          href="https://polygonscan.com/address/0x2f2963d1ce65e01f06c5cda78e34ad21ce8c8b99"
          target="_blank"
        >
          <div className="flex">
            Balance: {balance} MATIC
            <img src={Matic} alt="matic" className="w-6 h-6 ml-2" />
          </div>
        </a>
      </div>
      <div className="flex-[0.5] flex-col h-[100px] xl:h-[100px] mt-8 xl:mt-0 bg-white rounded-xl xl:ml-4 p-6 flex items-center justify-center">
        PHR-Pool เหลือ {data}
      </div>
    </div>
  );
}

export default PoolPHR;
