import { useState, useEffect } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { convertToThaiMonth, convertDateFullEngToThaiFormat } from "../../../utils/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Graph(props) {
    const [hospital, setHospital] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [mode, setMode] = useState(null);

    useEffect(() => {
        setHospital(props.hospital);
        setMonth(props.month);
        setYear(props.year);
        setMode(props.mode);
    }, [props.hospital, props.month, props.year, props.mode]);

    const [datasets, setDataSets] = useState();
    const [labels, setLabel] = useState();
    const [total, setTotal] = useState();

    useEffect(() => {
        if (year !== null && month !== null && hospital !== null) {
            (async () => {
                switch (mode) {
                  case "Month":
                      await ShowGrapMonth();
                      break;
                  case "Year":
                      await ShowGrapYear();
                      break;
                  default:
                      await ShowGrapDay();
                }
            })();
        }
      }, [year, month, hospital]);

    async function ShowGrapDay() {
        const r = await axios({
          method: "get",
          headers: {
            "db": "prod",
          },
          url: `https://golang.api.healthtag.io/consent-accumulate?hospitalAddress=${hospital}&year=${year}&month=${month}`,
          withCredentials: false,
        });
        if (r.status === 200) {
          convertDateFullEngToThaiFormat(r.data.labels);
          setDataSets(r.data.datasets);
          setLabel(r.data.labels);
          setTotal(r.data.total);
        }
    }

    async function ShowGrapMonth() {
        const r = await axios({
            method: "get",
            headers: {
              "db": "prod",
            },
            url: `https://golang.api.healthtag.io/consent-accumulate?hospitalAddress=${hospital}&year=${year}`,
            withCredentials: false,
        });
        if (r.status === 200) {
          convertToThaiMonth(r.data.labels);
            setDataSets(r.data.datasets);
            setLabel(r.data.labels);
            setTotal(r.data.total);
        }
    }

  async function ShowGrapYear() {
      const r = await axios({
        method: "get",
        headers: {
          "db": "prod",
        },
        url: `https://golang.api.healthtag.io/consent-accumulate?hospitalAddress=${hospital}`,
        withCredentials: false,
      });
      if (r.status === 200) {
        convertToThaiMonth(r.data.labels);
        setDataSets(r.data.datasets);
        setLabel(r.data.labels);
        setTotal(r.data.total);
      }
  }

    const m = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];
      let monthName = m[month - 1];

  return (
    <div className="flex-col w-full items-center grid grid-cols-1 xl:flex justify-center bg-white mt-4">
        <div className="flex max-w-[730px] w-full justify-between items-center ">
            <p className="text-[#2D3748] text-[18px] font-bold">จำนวนผู้สมัครสะสม</p>
            <div className="flex items-center">
                <p className="text-[#3354F4] text-[18px] font-bold mr-2">{total}</p>
                {mode === "Day" && 
                    <p className="text-[#949CB0] text-sm">/ เดือน {monthName} ปี {year}</p>
                }
                 {mode === "Month" && 
                    <p className="text-[#949CB0] text-sm">/ ปี {year}</p>
                }
                {mode === "Year" && 
                    <p className="text-[#949CB0] text-sm">/ ทั้งหมด</p>
                }
            </div>
        </div>
        {/* accumulate */}
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "top",
              },
              title: {
                display: false,
                text: "",
                color: "#949CB0",
              },
            },
            scales: {
              x: {
                border: {
                  display: true
                },
                grid: {
                  display: false,
                }
              },
              y: {
                position: 'right',

                border: {
                  display: false
                },
                grid: {
                  display: true,
                },
              }
            }
          }}
          data={{
            labels: labels,
            datasets: [
              {
                label: "ผู้สมัครสะสมทั้งหมด",
                data: datasets,
                backgroundColor: "#FFFFFF",
                borderColor: "#3354F4",
              },
            ],
          }}
          className="max-w-[730px] max-h-[190px]"
        />
      </div>
  )
}

export default Graph