import { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { convertToThaiMonthNoSplit, convertToThaiFormat } from "../../../utils/utils";

function Graph(props) {
    const [hospital, setHospital] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [mode, setMode] = useState(null);

    useEffect(() => {
        setHospital(props.hospital);
        setMonth(props.month);
        setYear(props.year);
        setMode(props.mode);
    }, [props.hospital, props.month, props.year, props.mode]);

    const [datasets, setDataSets] = useState();
    const [labels, setLabel] = useState();
    const [total, setTotal] = useState();

    useEffect(() => {
        if (year !== null && month !== null && hospital !== null) {
            (async () => {
                switch (mode) {
                    case "Month":
                        await ShowGrapMonth();
                        break;
                    case "Year":
                        await ShowGrapYear();
                        break;
                    default:
                        await ShowGrapDay();
                }
            })();
        }
      }, [year, month, hospital, mode]);

    async function ShowGrapMonth() {
        const r = await axios({
            method: "get",
            headers: {
              "db": "prod",
            },
            url: `https://golang.api.healthtag.io/consent-total?year=${year}&hospitalAddress=${hospital}`,
            withCredentials: false,
        });
        if (r.status === 200) {
            convertToThaiMonthNoSplit(r.data.labels);
            setDataSets(r.data.datasets);
            setLabel(r.data.labels);
            setTotal(r.data.total);
        }
    }

    async function ShowGrapDay() {
        const r = await axios({
            method: "get",
            headers: {
              "db": "prod",
            },
            url: `https://golang.api.healthtag.io/consent-month?year=${year}&month=${month}&hospitalAddress=${hospital}`,
            withCredentials: false,
        });
        if (r.status === 200) {
            convertToThaiFormat(r.data.labels)
            setDataSets(r.data.datasets);
            setLabel(r.data.labels);
            setTotal(r.data.total);
        }
    }

    async function ShowGrapYear() {
        const r = await axios({
            method: "get",
            headers: {
              "db": "prod",
            },
            url: `https://golang.api.healthtag.io/consent-total?hospitalAddress=${hospital}&year=0`,
            withCredentials: false,
        });
        if (r.status === 200) {
            setDataSets(r.data.datasets);
            setLabel(r.data.labels);
            setTotal(r.data.total);
        }
    }

    const m = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];
      let monthName = m[month - 1];

  return (
    <div className="flex-col w-full items-center grid grid-cols-1 xl:flex justify-center mt-4">
        <div className="flex max-w-[730px] w-full justify-between items-center ">
            <p className="text-[#2D3748] text-[18px] font-bold">จำนวนผู้สมัครรายใหม่</p>
            <div className="flex items-center">
                <p className="text-[#4FD1C5] text-[18px] font-bold mr-2">{total}</p>
                {mode === "Day" && 
                    <p className="text-[#949CB0] text-sm">/ เดือน {monthName} ปี {year}</p>
                }
                {mode === "Month" && 
                    <p className="text-[#949CB0] text-sm">/ ปี {year}</p>
                }
                {mode === "Year" && 
                    <p className="text-[#949CB0] text-sm">/ ทั้งหมด</p>
                }
            </div>
        </div>
        <Bar
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "top",
              },
              title: {
                display: false,
                text: "",
                color: "#949CB0",
              },
            },
            scales: {
              x: {
                border: {
                  display: true
                },
                grid: {
                  display: false,
                }
              },
              y: {
                position: 'right',

                border: {
                  display: false
                },
                grid: {
                  display: true,
                },
              }
            }
          }}
          data={{
            labels,
            datasets: [
              {
                label: "ผู้สมัครรายใหม่",
                data: datasets,
                backgroundColor: "#4FD1C5",
              },
            ],
          }}
          className="max-w-[730px] max-h-[190px]"
        />
      </div>
  )
}

export default Graph