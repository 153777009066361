import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Icon from "../../../assets/svg/icon.svg";
import Transaction from "../../../assets/svg/transaction.svg";
import Users from "../../../assets/svg/users.svg";
import Patient from "../../../assets/svg/patient.svg";
import Click from "../../../assets/svg/click.svg";

function Summary() {
  const [totalHospital, setTotalHospital] = useState(5);
  function calculateDifferentMonth(startDate, endDate) {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    return (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
  }
  //from JAN 2023 till today
  const differentMonth = calculateDifferentMonth(new Date(2023, 0), new Date());

  useEffect(() => {
    (async () => {
      const r = await axios({
        method: "get",
        headers: {
          "db": "prod",
        },
        url: `https://golang.api.healthtag.io/hospital`,
        withCredentials: false,
      });
      if (r.status === 200) {
        setTotalHospital(r.data.length);
      }
    })();
  }, []);

  const [totalTransaction, setTotalTransaction] = useState(5000000);
  const [averageTransaction, setAverageTransaction] = useState(30000);
  useEffect(() => {
    (async () => {
      // 2023
      let sum = 0;
      let r = await axios({
        method: "get",
        headers: {
          "db": "prod",
        },
        url: `https://golang.api.healthtag.io/patient-logs-total?year=2023`,
        withCredentials: false,
      });
      if (r.status === 200) {
        for (let i=0; i < r.data.datasets.length; i++) {
          sum += r.data.datasets[i]
        }
      }

      // current year
      r = await axios({
        method: "get",
        headers: {
          "db": "prod",
        },
        url: `https://golang.api.healthtag.io/patient-logs-total`,
        withCredentials: false,
      });
      if (r.status === 200) {
        for (let i=0; i < r.data.datasets.length; i++) {
          sum += r.data.datasets[i]
        }
      }
      setTotalTransaction(sum)
      setAverageTransaction((sum / differentMonth).toFixed(0));
    })();
  }, []);

  const [totalUser, setTotalUser] = useState(2000000);

 
  const [averageRegister, setAverageRegister] = useState(1000);
  useEffect(() => {
    (async () => {
      const r = await axios({
        method: "get",
        headers: {
          "db": "prod",
        },
        url: `https://golang.api.healthtag.io/consent-accumulate`,
        withCredentials: false,
      });
      if (r.status === 200) {
        setTotalUser(r.data.total);
        setAverageRegister((r.data.total / differentMonth).toFixed(0));
      }
    })();
  }, []);
  
  const data = [
    {
      id: 1,
      src: Icon,
      title: "จำนวนโรงพยาบาลที่เข้าร่วม network",
      num: totalHospital,
      unit: "โรงพยาบาล",
      suffix: false,
    },
    {
      id: 2,
      src: Transaction,
      title: "จำนวนการใช้งานสะสม  (transactions)",
      num: totalTransaction,
      unit: "ครั้ง",
      suffix: false,
    },
    {
      id: 3,
      src: Users,
      title: "จำนวนผู้ใช้งานในระบบ (Users)",
      num: totalUser,
      unit: "คน",
      suffix: false,
    },
    {
      id: 4,
      src: Patient,
      title: "อัตราการสมัครใช้งาน",
      num: `+ ${averageRegister}`,
      unit: "คน",
      suffix: true,
    },
    {
      id: 5,
      src: Click,
      title: "อัตราการใช้งาน",
      num: `+ ${averageTransaction}`,
      unit: "ครั้ง",
      suffix: true,
    },
  ];

  return (
    <div className="flex flex-col w-full h-[450px] xl:h-full justify-between">
      {data.map(({ id, src, title, num, unit, suffix }) => (
        <div key={id} className="flex w-full h-[80px] justify-between items-center  rounded-xl border-[#DFDFDF] border-2">
          <div className="flex flex-col h-full flex-[0.75]  items-start p-3 justify-between">
            <p className="text-[#A0AEC0] text-[14px]">
              {title}
            </p>
            <div>
              <span className="text-[18px] font-bold">{num}</span>
              <span className="text-[#48BB78] ml-2">{unit}</span>
              {suffix && <span className="text-[14px] text-[#A0AEC0]">/เดือน</span>}
            </div>
          </div>
          <div className="flex h-full flex-[0.25]  justify-center items-center">
            <img src={src} alt="" className="w-[45px]" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Summary;
